export const contents = {
  Chicas: {
    info: "Chicas",
    folder: 'Chicas',
    list: ["Café Vienés.jpg",
      "Chica en flor.jpg",
      "Chica uñas.jpg",
      "La chica del tigue bordado.jpg",
      "Maria Antonieta now.jpg",
      "Morgue.jpg",
      "Pin up azul.jpg",
      "Portada Morgue.jpg"
    ]
  },
  Comida: {
    info: "Comida",
    folder: 'Comida',
    list: ["Bibimbap.tiff",
      "Dulce de amor.jpg",
      "Panstel de queso chocolate y helado.jpg",
      "Pastel de flan y fresas con nata.jpg",
      "Pastel muffin.jpg",

    ]
  },
  Figurines: {
    info: "Figurines",
    folder: 'Figurines',
    list: [
      "Chica convers.jpg", "Chica de Tokio 1.jpg", "Chica de Tokio 2.jpg", "Chica minifrinchi.jpg", "Chica peliroja.jpg", "Chica trenza.jpg", "cursi.jpg", "gorro orejeras.jpg", "Matiuska.jpg", "Osita.jpg", "Perlas.jpg", "Topos.jpg", "Vestido cortina.jpg", "Vintage.jpg"

    ]
  },
  Ilustraciones: {
    info: "Ilustraciones",
    folder: 'Ilustraciones',
    list: [
      "Arandano.jpg",
      "La rata.jpg",
    ]
  }
}
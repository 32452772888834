import logo from './logo.svg';
import { useNavigate } from 'react-router-dom';


export const  Home =()=> {
  const navigate = useNavigate();

  return (
    <div className='menu'>
        <img className='menu__item' src={'images/boton_chica.jpg'} onClick={ ()=>navigate('Chicas')}  />
        <img className='menu__item' src={'images/boton_comic.jpg'} onClick={ ()=>navigate('Chicas')}  />
        <img className='menu__item' src={'images/boton_comida.jpg'} onClick={ ()=>navigate('Comida')}  />
        <img className='menu__item' src={'images/boton_estampados.jpg'} onClick={()=> navigate('Chicas')}  />
        <img className='menu__item' src={'images/boton_figurin.jpg'} onClick={ ()=>navigate('Figurines')}  />
        <img className='menu__item' src={'images/boton_ropa.jpg'} onClick={ ()=>navigate('Ilustraciones')}  />
        </div>
);
}


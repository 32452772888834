import React from 'react'


export const  Contact =({data})=> {
  return (
    <div>
      <h1>Contacto</h1>
 
    </div>
  );
}


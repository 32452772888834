import React from 'react'


export const  Gallery =({data, setUrlImage})=> {
  console.info(data.list)
  return (
    <div className="gallery">
        {data.list.map((el,i)=>{
          {console.info(`/images/gallery/${data.folder}/${el}`)}
        return(  <div className='gallery__item'>
                      <img  src={`images/gallery/${data.folder}/${el}`} onClick={()=>setUrlImage(`images/gallery/${data.folder}/${el}`)} />
            </div>)
        })}
 
    </div>
  );
}


import React from 'react'

import './App.scss';
import { Routes, Route } from "react-router-dom"
import { useNavigate } from 'react-router-dom';

import {Home} from './Home.js'
import {Gallery} from './Gallery.js'
import {Contact} from './Contact.js'
import {contents} from './contents.js'

function App() {
  const navigate = useNavigate();
  const [urlImage, setUrlImage] = React.useState('');
  return (
    <>
    <Modal urlImage={urlImage}setUrlImage={setUrlImage}/>

    <div className="App" style={{pointerEvents:urlImage?'none':'unset'}}>
      <header className="App-header" onClick={()=>navigate('/')}>  
      <img src={'images/header.png'}  />
      <div className='menu'>
        {/* <img src={'images/boton_store.png'} /> */}
        <img src={'images/boton_redes.png'}  />
        {/* <img src={'images/boton_hola.png'}  /> */}
        </div>
         </header>

        <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/Chicas" element={ <Gallery data={contents.Chicas} setUrlImage={setUrlImage}/> } />
        <Route path="/Comida" element={ <Gallery data={contents.Comida} setUrlImage={setUrlImage}/> } />
        <Route path="/Figurines" element={ <Gallery data={contents.Figurines} setUrlImage={setUrlImage}/> } />
        <Route path="/Ilustraciones" element={ <Gallery data={contents.Ilustraciones} setUrlImage={setUrlImage}/> } />
        <Route path="/contact" element={ <Contact/> } />




        <Route path="/contacto" element={ <Contact /> } />
      </Routes>


    </div>
    </>
  );
}

export default App;

const Modal = ({urlImage, setUrlImage}) => {
const handleClick = ()=>{
  setUrlImage(undefined)
}
  return (urlImage?<div className='modal'>
      <div className='modal__backdrop'  />    
    <div className='modal__container'>     
    <div className='modal__close' onClick={handleClick}/>                   <img  src={urlImage}  />
    </div>


</div>:null)
}